import React from "react";

import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import JS from "../assets/javascript.png";
import Reactimg from "../assets/react.png";
import GitHub from "../assets/github.png";
import Node from "../assets/node.png";
import MongoDB from "../assets/mongo.png";
import AWS from "../assets/aws.png";

const Skills = () => {
  return (
    <div
      name="skills"
      className="w-full md:h-screen bg-[#0a192f] text-gray-300"
    >
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-pink-600 ">
            Skills
          </p>
          <p className="py-4">Technologies I use</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={JS} alt="javascript icon" />
            <p>Javascript</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Reactimg} alt="react icon" />
            <p>React</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Node} alt="nodejs icon" />
            <p>NodeJs</p>
          </div>
          <div className=" hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={MongoDB} alt="mongodb icon" />
            <p>MongoDB</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
